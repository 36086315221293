<template>
  <div v-if="reportType">
    <action-header
      :actions-model="headerModel"
      :page="page"
    />

    <orders-statistic-panel :data-source="reportType.raw.dataSource" />
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters } from 'vuex'

// Components
import ActionHeader from '../../components/action-header/ActionHeader.vue'
import OrdersStatisticPanel from '../../components/statistic-panel/ReportStatisticPanel.vue'

export default {
  name: 'ReportType',
  components: {
    ActionHeader,
    OrdersStatisticPanel
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters([
      'reportType'
    ]),
    page () {
      return {
        id: this.reportType && this.reportType.id,
        name: this.reportType && this.reportType.id
          ? this.$t('Report type')
          : this.$t('New report type')
      }
    },
    headerModel () {
      return [
        {
          section: 'BackAction',
          className: 'col-sm-1 q-pr-md hide-on-mobile',
          options: [
            {
              id: 'back',
              type: 'button',
              icon: 'arrow_back',
              variant: 'light',
              style: 'white-space: nowrap;',
              label: this.$t('Back'),
              onClick: this.handleBack
            }
          ]
        },
        {
          section: 'Title',
          className: 'col-sm-3 mobile-title',
          options: [
            {
              id: 'title',
              type: 'text',
              className: 'text-white',
              value: this.reportType.id ? this.$t('Report Type ID: ') + this.reportType.id : this.$t('New report type')
            }
          ]
        },
        {
          section: 'Actions',
          className: 'col-sm-4 row text-center row justify-center',
          options: []
        }
      ]
    }
  },
  mounted () {
    if (this.$route.params.id && !this.reportType) {
      this.loadReportType(this.$route.params.id)
    }
  },
  methods: {
    ...mapActions([
      'loadReportType'
    ]),
    handleBack () {
      this.$router.back()
    }
  }
}
</script>
